import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'

import Layout from '../components/layout'
import Img from 'gatsby-image'
import '../components/App.scss'

class RootIndex extends React.Component {
  render() {
    const homepageContent = get(this, 'props.data.contentfulHomepageContent')
    return (
      <Layout location={this.props.location}>
        <header className='header'>
          <div className='header-logo'>
            <Img
              fluid={{ ...homepageContent.logo.fluid, aspectRatio: 1020 / 122}}
              alt="Pilates & Wellbeing Clinic London"
              fadeIn="false"
            />
          </div>
        </header>
        <article className='bio'>
          <div className='bio-container'>
            <div className='bio-image'>
              <Img
                fluid={{ ...homepageContent.bioImage.fluid, aspectRatio: 224 / 280}}
                alt="Gabby"
                fadeIn="false"
              />
            </div>
            <div className='bio-copy' dangerouslySetInnerHTML={{ __html: homepageContent.bioCopy.childMarkdownRemark.html }}></div>
          </div>
        </article>
        <article className='about'>
          <div className='about-container'>
            <div className='about-title'>
              <Img
                fluid={{ ...homepageContent.aboutTitle.fluid, aspectRatio: 184 / 43}}
                alt="About"
                fadeIn="false"
              />
            </div>
            <div className='about-copy' dangerouslySetInnerHTML={{ __html: homepageContent.aboutCopy.childMarkdownRemark.html }}></div>
          </div>
        </article>
        <section className='homepage-images'>
          <ul>
          {homepageContent.homepageImages.map((homepageImage) => (
            <li key={homepageImage.id}>
              <Img
                fluid={{ ...homepageImage.fluid, aspectRatio: 359 / 237}}
                alt="Homepage Image"
                fadeIn="false"
              />
            </li>
          ))}
          </ul>
        </section>
      </Layout>
    )
  }
}

export default RootIndex

export const pageQuery = graphql`
  query HomepageQuery {
    contentfulHomepageContent {
      logo {
        fluid(maxWidth: 1020) {
          base64 
          aspectRatio 
          src 
          srcSet 
          sizes 
        }
      }
      aboutCopy {
        childMarkdownRemark {
          html
        }
      }
      aboutTitle {
        fluid {
          base64 
          aspectRatio 
          src 
          srcSet 
          sizes 
        }
      }
      bioCopy {
        childMarkdownRemark {
          html
        }
      }
      bioImage {
        fluid {
          ...GatsbyContentfulFluid_tracedSVG
          base64 
          aspectRatio 
          src 
          srcSet 
          sizes 
        }
      }
      homepageImages {
        id
        fluid {
          ...GatsbyContentfulFluid_tracedSVG
          base64 
          aspectRatio 
          src 
          srcSet 
          sizes 
        }
      }
    }
  }
 `
